// import React from "https://cdn.skypack.dev/react@17.0.1";
// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import CircularProgress from '@mui/material/CircularProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import axios from 'axios'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { collection, getDocs } from "firebase/firestore"; 
import { query, where, orderBy} from "firebase/firestore";


import { useLocation } from 'react-router-dom';


import {
    useNavigate,
    // useRouteMatch
  } from "react-router-dom";







import './style.scss'

// const transcriptsJSON = require('./data/transcripts.json') ; 
// const videosJSON = require('./data/video.json') ;
const channelsJSON = require('./data/channels.json') ; 
// const API_ENDPOINT = "http://127.0.0.1:3005"//http://ec2-3-144-221-188.us-east-2.compute.amazonaws.com:3000"
const API_ENDPOINT = "https://wordtoobe-api.replit.app"//https://3.131.105.179:8080"



// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);




// import * as fs from 'fs';
// const path = require( 'path' );

var style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    scrollTop:200,
    p: 4,
    overflow: "scroll"
  };

  


 function SearchVideos(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [listOfVideosFromCurrentChannel, setListOfVideosFromCurrentChannel] = useState([]);
    const [gptResponse, setGptResponse] = useState(null)
    const [loadGptResponse, setLoadGptResponse] = useState(false)
    // const [showGptResponse, setShowGptResponse] = useState(false)



    const [items, setItems] = useState([]);
    const [q, setQ] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [searchParam] = useState(["capital", "name", "numericCode"]);
    const [filterParam, setFilterParam] = useState(["All"]);
    const [element, setElement] = useState(null);
    const [videoId, setVideoId] = useState(null);
    const [foundTranscriptText, setFoundTranscriptText] = useState(null)
    const [foundTranscriptSentence, setFoundTranscriptSentence] = useState(null)
    const [foundTranscriptParagraphs, setFoundTranscriptParagraphs] = useState(null)


    const [transcripts, setTranscripts] = useState([]);

    const [selectedChannel, setSelectedChannel] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([""]);



    const [open, setOpen] = useState(false);

    const ref = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();  


    function goTo(path) {
      navigate(path);
    }


    // function turnToTime(time){
    //     let hour = 
    // }
  
  





    useEffect(() => {


        //GET THE CHANNEL ID FROM THE PATH NAME
        let pathChannelID =  window.location.href.split("/").at(-1)
        let pageChannel = channelsJSON.filter((item) => {return( item['channelId'] ==pathChannelID)})[0]
        setSelectedChannel(pageChannel);
        console.log("Channel show on this page: ", pageChannel)
        


        //Alternative to connecting to firebase
        // let jsonvideos = videosJSON.filter((item) => {return( item['channelID'] == pathChannelID)});
        // setIsLoaded(true)
        // setItems(jsonvideos)

    

        //
        const firebaseQueryForListOfVideos = query(collection(db, "videos"), where("channelId", "==", pathChannelID), orderBy("publishedAt", "desc"));

        getDocs(firebaseQueryForListOfVideos).then(querySnapshot =>{


            let listOfVideosFromChannel = querySnapshot.docs.map((doc)=>doc.data()).filter((item) => {return( item['text'] != 'error')})

            console.log(listOfVideosFromChannel)
            setListOfVideosFromCurrentChannel(listOfVideosFromChannel);
            setIsLoaded(true);

            // setElement(document.getElementById("modal"))

            // (error) => {
            //     setIsLoaded(true);
            //     setError(error);
            // }


        })


    }, [open]);



    const data = Object.values(listOfVideosFromCurrentChannel);

    function search(items) {
        return items.filter((item) => {
            if(!item['text'] && !searchVal) return true
            if(!item['text']) return false

            return(
                item['text']
                    .toString()
                    .toLowerCase()
                    .indexOf(searchVal.toLowerCase()) > -1
        
            )
 
        });
    }

    function clear(){

      setLoadGptResponse(false)
      setGptResponse(null)


    }
    const askGPT= async (query)=>{


        // var request = new XMLHttpRequest();
        // request.open('POST', 'http://127.0.0.1:5000/askgpt', true);
        // request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        // request.send({"query": "what is the best niche to be a creator in?"});
        let apiUrl = API_ENDPOINT

        const url = apiUrl+'/askgpt'; // Replace with your actual URL
        const data = {"query":query, "channelId":selectedChannel.channelId}; // Replace with your data

        setLoadGptResponse(true)

        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*' 
        },
        body: JSON.stringify(data),
        });

        const responseData = await response.json();
        setLoadGptResponse(false)
        console.log(responseData)
        setGptResponse(responseData['response'])
        setListOfVideosFromCurrentChannel(responseData['videos'])

        // if(gptResponse=="yes"){
        //     setGptResponse(null)
        // }else{
        //     setGptResponse("yes")
        // }
        //responseData['response'])
    }

    function formatSecondsToTimestamp(seconds) {
        const timeDelta = new Date(0, 0, 0, 0, 0, seconds);
        const formattedTimestamp = timeDelta.toTimeString().substring(0, 8);
        return formattedTimestamp;
    }

    const openVideoAtTime = (url,time) => {
        window.open(url+"&t="+time+"s", '_blank');
      };
    


    const onFocus = event => {

        if(event.target.autocomplete)
        {
            
          event.target.autoComplete = "off";
        //   event.target.setAttribute('autoComplete', 'off')
          event.target.removeAttribute('readonly')

        }
     
     };
    





    if (error) {
        return (
            <p>
                {error.message}, if you get this error, the free API I used
                might have stopped working, but I created a simple example that
                demonstrate how this works,{" "}
                <a href="https://codepen.io/Spruce_khalifa/pen/mdXEVKq">
                    {" "}
                    check it out{" "}
                </a>{" "}
            </p>
        );
    } else if (!isLoaded) {
        return <>loading...</>;
    } else {
        return (

            <>
            <div className="wrapper">


                {/* <div className="search-channel-header"> */}
                    <div className="search-channel-nav">
                         <img className="channel-img" src={selectedChannel.thumbnail}></img><p className="channel-title">{selectedChannel.title}</p>
                    </div>

                {/* </div> */}

         
                <div className="search-wrapper">  
                
                      

                    <input
                        id="search-form"
                        className="search-input"
                        placeholder="Search for..."
                        value={q}
                        name={"input_"+Date.now()}
                        // name={`input_${Math.random().toString(36).substr(2, 10)}`}
                        onFocus={(e)=>onFocus(e)}
                        onChange={(e) => setQ(e.target.value)}
                        readonly="readonly"
                        // autoComplete="new-password"
                        autoComplete="off"


                    />
                    <span className="sr-only">Search countries here</span>
                    <div className="buttons-wrapper">
                    <button  className="button-format search-button" onClick={()=>askGPT(q)}>Ask GPT</button>
                    {/* <button className="search-button" onClick={()=>clear()}>search</button> */}
                    </div>

                </div>

                <div className={`chatgpt-response ${gptResponse ? 'expanded' : 'expanded'}` }>
                  
          
                     
                   
                    <div className="section-title" >Response</div>
                    {loadGptResponse?
                       <CircularProgress />:
                       
                        (gptResponse?
                            <>{gptResponse}</>:
        
                                <>Enter a question for GPT based on the videos in this channel</>)

                    }
                    

                </div>

                <ul className="card-grid">
                    {search(data).map((item) => (
                        <li >
                            <article className="card" key={item.videoId}>
                                <div className="card-items">
                                    <div className="card-image">
                                        <img
                                            src={item.thumbnail}
                                            alt={item.title}
                                        />
                                    </div>
                                    <div className="card-content">
                                        <h3 className="card-name">{item.title}</h3>
                                        <ol className="card-list">
                                            <li>
                                                
                                                <span>{item.publishedAt}</span>
                                                {gptResponse? 
                                                    
                                                    <div className="card-excerpt">
                                                       <span className="excerpt-time"> <OpenInNewIcon className="excerpt-icon" onClick={()=>openVideoAtTime(item.videoUrl,item.timeStamp)}/>{`${formatSecondsToTimestamp(item.timeStamp)}: `}</span>
                                                        {item.excerpt}
                                                    </div>:
                                                    <></>
                                                }
                                                <span></span>
                                    
                                            </li>
                                        
                                        </ol>
                                    </div>
                                </div>
                           
                            </article>
                        </li>
                    ))}
                    
                </ul>




            </div>
            </>
        );

        
    }
}



export default SearchVideos;
//ReactDOM.render(<App />, document.getElementById("root"));
