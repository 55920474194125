// import React from "https://cdn.skypack.dev/react@17.0.1";
// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { collection, getDocs } from "firebase/firestore"; 
import { query, where, orderBy} from "firebase/firestore";

import { doc, getDocFromCache } from "firebase/firestore";

import {
    useNavigate,
    // useRouteMatch
  } from "react-router-dom";



import './style.scss'

const firebaseConfig = {
    apiKey: "AIzaSyB9wKtQbbkDt-riUmKEZthBxGXnPkUdNvo",
    authDomain: "analytics-c4f16.firebaseapp.com",
    projectId: "analytics-c4f16",
    storageBucket: "analytics-c4f16.appspot.com",
    messagingSenderId: "310178373248",
    appId: "1:310178373248:web:d4309e269806eae338d065",
    measurementId: "G-MXZQCYT39B"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const channelsJSON = require('./data/channels.json') ; 






// import * as fs from 'fs';
// const path = require( 'path' );




 function SearchChannels() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [channels, setChannels] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    const [selectedChannel, setSelectedChannel] = useState([""]);


    const navigate = useNavigate();
    // let { path, url } = useRouteMatch();


    function handleClick(channelID) {
      navigate("/channel/"+channelID);
    }
  
    useEffect(() => {

        setIsLoaded(true);
        setChannels(channelsJSON);


        const q = query(collection(db, "channels"));

        getDocs(q).then(querySnapshot =>{

            let channelsList = querySnapshot.docs.map((doc)=>doc.data()).filter(channel => channel.status !='hide')

            setIsLoaded(true);
            setChannels(channelsList);
            console.log(channelsList)
            console.log('loaded {video id}.');

            // (error) => {
            //     setIsLoaded(true);
            //     setError(error);
            // }


        })
 
    }, []);



    const data = Object.values(channels);

    function search(items) {
        return items.filter((item) => {

            console.log(item)
            if(!item['title'])return false
            return(
                item['title']
                    .toString()
                    .toLowerCase()
                    .indexOf(searchVal.toLowerCase()) > -1
        
            )
 
        });
    }



    if (error) {
        return (
            <p>
                {error.message}, if you get this error, the free API I used
                might have stopped working, but I created a simple example that
                demonstrate how this works,{" "}
                <a href="https://codepen.io/Spruce_khalifa/pen/mdXEVKq">
                    {" "}
                    check it out{" "}
                </a>{" "}
            </p>
        );
    } else if (!isLoaded) {
        return <>loading...</>;
    } else {
        return (

            <>
            <div className="wrapper">



                <div className="search-channel-header">


                    <div className="search-channel-nav">
                        <p className="channel-title">Choose Channel to Search</p>
                    </div>

                    <div className="youtube-video-search">

                {/* <div className="search-container">
                    <input 
                        value={searchVal}  
                        onChange={(e) => setSearchVal(e.target.value)}class="youtube-video-url-input"
                        placeholder="Find a channel to search...">

                    </input>
                    <button  onClick={()=>setSearchVal(searchVal)} className="get-video-button button-format">search</button>
                </div> */}

            </div>

                </div>

                <ul className="card-grid">
                    {search(data).map((item) => (
                        <li onClick={() => {handleClick(item.channelId)}}>
                
                            <article className={"card "+(item.status=='pending'?("pending"):"")} >
                                <div className="card-image">
                                    <img
                                        src={item.thumbnail}
                                        alt={item.title}
                                    />
                                </div>
                               { (item.status=='pending'?(<div className="pending-text">COMING SOON</div>):"")}
                                <div className="card-content">
                                    <h3 className="card-name">{item.title}</h3>
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>




            </div>
            </>
        );

        
    }
}



export default SearchChannels;
//ReactDOM.render(<App />, document.getElementById("root"));
