// import React from "https://cdn.skypack.dev/react@17.0.1";
// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore"; 
import { query, where, orderBy} from "firebase/firestore";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';



import { doc, getDocFromCache } from "firebase/firestore";

import { useLocation } from 'react-router-dom';

import {
    useNavigate,
    // useRouteMatch
  } from "react-router-dom";



  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
//   import faker from 'faker';
  import './style.scss'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );



  
  export const options = {
    responsive: true,

    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Enter a term above to search...',
      },
    },
  };

  const videos = require('./data/video.json') ; 
  const trendsdata = require('./data/data.json') ; 
  const labels = trendsdata['labels']
  
  export const data = {
    labels: labels,
    datasets: [
      {
        label: 'Videos',
        data: [],//trendsdata['values'],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  

export const searchChannelJson = {
    "channelId": "UCamLstJyCa-t5gfZegxsFMw",
    "title": "Colin and Samir",
    "uploads": "UUamLstJyCa-t5gfZegxsFMw",
    "description": "About creators for creators.\n\nInterested in working together? - you can email colinandsamir@gmail.com with your project info / inquiry. \n\n🌍 Advertise With Us - partnerships@publishprojects.com\n🌍 Book us - colinandsamir@unitedtalent.com\n\nColin and Samir are YouTube Creators based in Los Angeles with a long history of working with Creators large and small. They got their start working on their first project, The Lacrosse Network - A YouTube sports network dedicated to lacrosse. Today they tell stories about creators and are fans of YouTube news and culture. \n\nThe gear they use: https://kit.co/colinandsamir\n\nSEND US A STORY to colinandsamir@gmail.com\n\nFollow us on TWITTER: http://www.twitter.com/@colinandsamir\n\nFollow us on INSTAGRAM\n\nColin @colinrosenblum\n\nSamir @SamirChaudry\n\nVisit our website: www.ColinandSamir.com",
    "thumbnail": "https://yt3.ggpht.com/ytc/AMLnZu9CjHnRNDUF_4vYI258iRhPSQso_en2JLUeL3nHXw=s800-c-k-c0x00ffffff-no-rj"
}



const firebaseConfig = {
    apiKey: "AIzaSyB9wKtQbbkDt-riUmKEZthBxGXnPkUdNvo",
    authDomain: "analytics-c4f16.firebaseapp.com",
    projectId: "analytics-c4f16",
    storageBucket: "analytics-c4f16.appspot.com",
    messagingSenderId: "310178373248",
    appId: "1:310178373248:web:d4309e269806eae338d065",
    measurementId: "G-MXZQCYT39B"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


var style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    scrollTop:200,
    p: 4,
    overflow: "scroll"
  };

  

 function SearchTrends(props) {

    //State for data loading
    const [isLoaded, setIsLoaded] = useState(true);
    const [searchChannel, setSelectedChannel] = useState(searchChannelJson);


    //state for chart data
    const [searchTerm, setSearchTerm] = useState("")
    const [chartData, setChartData] = useState(data)
    const [chartOptions, setChartOptions] = useState(options)
    const [chartResults, setChartResults] = useState([])

    //state for transcripts
    const [modalVideo,setModalVideo] = useState([])
    const [modalTranscript,setModalTranscript] = useState([])
    const [open, setOpen] = useState(false);


    useEffect(() => {
 
    }, []);


    function compare( a, b ) {
        if ( a.publishedAtKey < b.publishedAtKey ){
          return -1;
        }
        if ( a.publishedAtKey > b.publishedAtKey ){
          return 1;
        }
        return 0;
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false);}

    function triggerVideoTranscript(videoObj){
        setModalVideo(videoObj)
        // get video details from firebase
        const q = query(collection(db, "Transcripts"), where("videoId", "==", videoObj.videoId));
        getDocs(q).then(querySnapshot =>{
            let transcriptsList = querySnapshot.docs.map((doc)=>doc.data())
            //set transcript for video
            setModalTranscript(transcriptsList[0].transcript)
            setOpen(true)
        })

    }
      
    function findWord(term, collectionList){

        //Find videos that have the word
        collectionList = collectionList.map(obj =>{
            //create a key vased on year-month combinatiob
            obj['publishedAtKey'] = obj['publishedAt'].split('-')[0] + '-' +  obj['publishedAt'].split('-')[1]
            //count the video if it has the word we're searching for
            if (  obj['transcript'] ) obj['count_word'] = obj['transcript'].toLowerCase().indexOf(term) > -1
            else obj['count_word'] = false
            return obj
        })


        //count how many videos in each month have used the term
        var result = [];
        var all_video_ids = []
        collectionList.reduce(function(res, value) {
        if (!res[value.publishedAtKey]) {
            res[value.publishedAtKey] = { publishedAtKey: value.publishedAtKey, count: 0 , videoIds: []};
            result.push(res[value.publishedAtKey])
        }
        res[value.publishedAtKey].count += value.count_word;
        if(value.count_word > 0){
            res[value.publishedAtKey].videoIds.push(value.videoId)
            all_video_ids.push(value.videoId)

        }
        return res;
        }, {});
        result = result.sort( compare)

        //Update the chart data
        var new_data = 
        {
            labels: result.map(obj => obj['publishedAtKey']),
            datasets: [
              {
                label: 'Videos',
                data: result.map(obj => obj['count']),
                backgroundColor: 'rgba(255, 245, 99, 0.9)',
              }
            ],
          }

        setChartData(new_data)


        //show all videos with term as the results
        var chart_results = collectionList.filter(obj => {
            return all_video_ids.indexOf(obj.videoId) > -1
        })
        setChartResults(chart_results)


        var options_ = {
            responsive: true,
            plugins: {
            //   legend: {
            //     position: 'top',
            //   },
              title: {
                display: true,
                text: `Number of Videos discussing ${term}`,
              },
            },

        onClick: function(evt, element) {   
            

            var idx = element[0].index
            console.log(idx)
            var chart_results = collectionList.filter(obj => {
                return result[idx].videoIds.indexOf(obj.videoId) > -1
            })
            setChartResults(chart_results)
        }

        }


        setChartOptions(options_)
    }
    


    


    if (false) {
        return (
            <p>
            if you get this error, the free API I used
                might have stopped working, but I created a simple example that
                demonstrate how this works,{" "}
                <a href="https://codepen.io/Spruce_khalifa/pen/mdXEVKq">
                    {" "}
                    check it out{" "}
                </a>{" "}
            </p>
        );
    } else if (!isLoaded) {
        return <>loading...</>;
    } else {
        return (

            <>
            <div className="wrapper">
                <div className="search-channel-header">

                    <ul className="search-channel-nav">
                        <li> <img className="channel-img" src={searchChannel.thumbnail}></img><p className="channel-title">{searchChannel.title}</p></li>
                    </ul>
    
                    <div className="search-wrapper">        

                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="search-input"
                                placeholder="Search for..."
                                value={searchTerm}
                                onChange={(e) => {setSearchTerm(e.target.value)}}
                            />
                            <span className="sr-only">Search countries here</span>
                            <button className="search-button" onClick={()=>{findWord(searchTerm,videos)}}>search</button>                    

                    </div>
                </div>

                <div className="chart-section">
                <div className="chart-wrapper">  <Bar options={chartOptions} data={chartData} /></div>
                <div className="results-section">

                <h2 className="results-title">Results</h2>

                <div className="results-wrapper card-grid">
                {chartResults.map((item) => (
                        <li onClick={() => {triggerVideoTranscript(item)}}>
                            <article className="card results-card" key={item.videoID}>
                                <div className="card-items">
                                <div className="card-image">
                                    <img
                                        src={item.thumbnail}
                                        alt={item.title}
                                    />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-name">{item.title}</h3>
                                    <ol className="card-list">
                                        <li> 
                                            <span>{item.publishedAt}</span>
                                        </li>
                                       
                                    </ol>
                                </div>
                                </div>
                            </article>
                        </li>
                    ))}
                    </div>
                </div>

                </div>
            </div>


            <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box  sx={style}>

                        <div className="transcript-modal-header">
                        <h3 id="subline" >
                            {"Colin & Samir"}
                        </h3>
                        <h2 id="title" >
                            {modalVideo.title}
                        </h2>
                        </div>
                   

                        <div id ="modal">

                        { ( 
                                

                                modalTranscript.map(item => {
                                    
                                    return (<>
                                    <span className="transcript-modal-time">{(item['start'])/60}</span><br></br>
                               
                                    {/* {x.map(item =>  */}
                            
                                        <div  key ={item.start}>
                                            <span className="transcript-modal-text" 
                                            style={{ background: item? ((item.id == "XXXX") 
                                                ? "yellow" : ""):""}}>{item.text}</span>
                                            
                                        </div>
                                    <br></br>
                                    <br></br>
                                    </>)
                            })
                             )}
                       </div>   
                        </Box>
                    </Modal>
                </div>

            </>
        );

        
    }
}


export default SearchTrends;
