// import React from "https://cdn.skypack.dev/react@17.0.1";
// import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import CircularProgress from '@mui/material/CircularProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';


import { collection, getDocs } from "firebase/firestore"; 
import { query, where, orderBy} from "firebase/firestore";

import { useLocation } from 'react-router-dom';


import {
    useNavigate,
    // useRouteMatch
  } from "react-router-dom";







import './style.scss'

const channelsJSON = require('./data/channels.json') ; 
// const API_ENDPOINT = "http://127.0.0.1:5000"//http://ec2-3-144-221-188.us-east-2.compute.amazonaws.com:3000"
const API_ENDPOINT = "https://wordtoobe-api.replit.app"//https://3.131.105.179:8080"



// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};
  


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);




 function SearchVideos(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [listOfVideosFromCurrentChannel, setListOfVideosFromCurrentChannel] = useState([]);
    const [gptResponse, setGptResponse] = useState(null)
    const [loadGptResponse, setLoadGptResponse] = useState(false)
    const [loadTranscription, setLoadTranscription] = useState(false)

    // const [showGptResponse, setShowGptResponse] = useState(false)



    const [items, setItems] = useState([]);
    const [q, setQ] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);




    const [open, setOpen] = useState(false);

    const navigate = useNavigate();



const transcriptsList = [
    {
        "videoId": "YUx-PavD7EA",
        "start": 0.0,
        "text": "I can't live with that",
        "num_words": 22,
        "word_index": 0,
        "transcriptId": "YUx-PavD7EA_0.0",
        "prevId": 0,
        "nextId": "YUx-PavD7EA_15.71"
    },
    {
        "videoId": "YUx-PavD7EA",
        "start": 15.71,
        "text": "it is Thursday morning right now the day",
        "num_words": 40,
        "word_index": 22,
        "transcriptId": "YUx-PavD7EA_15.71",
        "prevId": "YUx-PavD7EA_0.0",
        "nextId": "YUx-PavD7EA_19.05"
    }]


    function goTo(path) {
      navigate(path);
    }


    // function turnToTime(time){
    //     let hour = 
    // }
  
  





    useEffect(() => {




        //Alternative to connecting to firebase
        // let jsonvideos = videosJSON.filter((item) => {return( item['channelID'] == pathChannelID)});
        setIsLoaded(true)
        // setItems(jsonvideos)

    

        //
   

    }, []);



    const data = Object.values(listOfVideosFromCurrentChannel);

    function search(items) {
        return items.filter((item) => {
            if(!item['text'] && !searchVal) return true
            if(!item['text']) return false

            return(
                item['text']
                    .toString()
                    .toLowerCase()
                    .indexOf(searchVal.toLowerCase()) > -1
        
            )
 
        });
    }

    function clear(){

      setLoadGptResponse(false)
      setGptResponse(null)


    }

    // const askGPT= async (query)=>{


    //     // var request = new XMLHttpRequest();
    //     // request.open('POST', 'http://127.0.0.1:5000/askgpt', true);
    //     // request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    //     // request.send({"query": "what is the best niche to be a creator in?"});

    //     const url =API_ENDPOINT// 'http://127.0.0.1:5000/askgpt'; // Replace with your actual URL
    //     const data = {"query":query}; // Replace with your data

    //     setLoadGptResponse(true)

    //     const response = await fetch(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Origin':'*' 
    //     },
    //     body: JSON.stringify(data),
    //     });

    //     const responseData = await response.json();
    //     setLoadGptResponse(false)
    //     console.log(responseData)
    //     setGptResponse(responseData['response'])
    //     console.log(responseData['videos'])
    //     setListOfVideosFromCurrentChannel(responseData['videos'])

    //     // if(gptResponse=="yes"){
    //     //     setGptResponse(null)
    //     // }else{
    //     //     setGptResponse("yes")
    //     // }
    //     //responseData['response'])
    // }

    const askGptAboutVideo= async (query, video)=>{


        // var request = new XMLHttpRequest();
        // request.open('POST', 'http://127.0.0.1:5000/askgpt', true);
        // request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        // request.send({"query": "what is the best niche to be a creator in?"});

        const url = API_ENDPOINT+'/askgpt-video'; // Replace with your actual URL
        const data = {"query":query,'video':selectedVideo,'sentences':selectedVideo.sentences}; // Replace with your data

        setLoadGptResponse(true)


        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        });


        console.log('Asking GPT about video: ',selectedVideo.title)

        const responseData = await response.json();
        setLoadGptResponse(false)
        setGptResponse(responseData['response'])
        setListOfVideosFromCurrentChannel(responseData['videos'])

        console.log("GPT responded: ",responseData['response'])
        console.log("GPT responded: ",responseData['response'])



        // if(gptResponse=="yes"){
        //     setGptResponse(null)
        // }else{
        //     setGptResponse("yes")
        // }
        //responseData['response'])
    }


    function formatSecondsToTimestamp(seconds) {
        const timeDelta = new Date(0, 0, 0, 0, 0, seconds);
        const formattedTimestamp = timeDelta.toTimeString().substring(0, 8);
        return formattedTimestamp;
    }

    const openVideoAtTime = (url,time) => {
        console.log("aaa",url,time)

        window.open(url+"&t="+time+"s", '_blank');
      };
    
    
      function getVideoIdFromUrl(url){
        console.log(url)
        const regex = /[?&]v=([^&]+)/;
        const match = url.match(regex);

        if (match && match[1]) {
        const videoId = match[1];
        console.log("VIDEO_ID:", videoId);
        return videoId;
        } else {
        console.log("VIDEO_ID not found in the URL.");
        return null
}
      }


    const clearVideo = () =>{
        // setIsLoaded(false);
        setListOfVideosFromCurrentChannel([]);
        setGptResponse(null);
        setLoadGptResponse(false);
    }


    const getVideoInfo = async (url)=>{


        console.log("getVideoInfo()")
        setLoadTranscription(true)



        // //TEST
        // let sentences = sentencesJSON
        // let selectedVideo = selectedVideoJSON

        // setSelectedVideo({'title': selectedVideo.title, 'img_url':selectedVideo.thumbnail,'sentences':sentences})

        // return

        let GOOGLE_DEVELOPER_KEY="AIzaSyCCWYqORY_TtpiAz-fzM4wuol6vMiGWV9E"

        const apiKey = GOOGLE_DEVELOPER_KEY;
        const videoId = getVideoIdFromUrl(url)
        const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,contentDetails`;

        console.log('CONSOLE.TEST')

        const response = await fetch(apiUrl)
        const responseData = await response.json()

        console.log("Video Response: ", responseData)

        let video = {}
        video['title'] = responseData.items[0]['snippet']['title'];
        video['channelId'] = responseData.items[0]['snippet']['channelId'];
        video['videoId'] = videoId
        video['publishedAt'] = responseData.items[0]['snippet']['publishedAt'].split("T")[0]
        video['videoUrl'] = "https://www.youtube.com/watch?v="+videoId
        video['duration'] = ''//getYoutubeDuration(responseData.items[0]['contentDetails']['videoId']  )
        video['img_url'] = responseData.items[0]['snippet']['thumbnails']['standard']['url'];
        video['sentences'] = 'loading'


        //first see if it exists in the database
        const firebaseQueryForListOfVideos = query(collection(db, "WhisperTranscriptions"), where("videoId", "==", videoId));

        getDocs(firebaseQueryForListOfVideos).then(querySnapshot =>{


            console.log(querySnapshot.docs[0])
            if (!querySnapshot.docs[0]){

                try{

                    const data = {"query":videoId}; // Replace with your data

                    // setLoadGptResponse(true)
            
                    const url = API_ENDPOINT+'/youtubeapi'; // Replace with your actual URL

                    const response = fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                    }).then(res => {
                        res.json().then((res)=>{
                            console.log(res)
                            console.log("A: ",data)
                            video['sentences'] = res['response']
                            setSelectedVideo(video)
                            clearVideo()
                        })
                    
                    })
                    // const responseData = await response.json();
                    // setLoadGptResponse(false)
                    // console.log(responseData)
                    // setGptResponse(responseData['response'])
                }catch{

                    // setSelectedVideo({'title': title, 'img_url':img_url,'sentences':'unavailable'})
                    video['sentences'] = 'unavailable'
                    setSelectedVideo(video)
                    console.log("transcriptData.sentences")
                    clearVideo()
                }


     
                return
            }else{
                let transcriptData = querySnapshot.docs[0].data()
                video['sentences'] = transcriptData.sentences
                setSelectedVideo(video)
                clearVideo()


                console.log(transcriptData.sentences)

                return

            }

           


        
        })



     
    }

    if (error) {
        return (
            <p>
                {error.message}, if you get this error, the free API I used
                might have stopped working, but I created a simple example that
                demonstrate how this works,{" "}
                <a href="https://codepen.io/Spruce_khalifa/pen/mdXEVKq">
                    {" "}
                    check it out{" "}
                </a>{" "}
            </p>
        );
    } else if (!isLoaded) {
        return <>loading...</>;
    } else {
        return (

            <>
            <div className="wrapper">


                {/* <div className="search-channel-header"> */}
                    <div className="search-channel-nav">
                        <p className="channel-title">Search a Video</p>
                    </div>

                {/* </div> */}
                <div className="youtube-video-search">



                    <div className="transcribe-wrapper">

                    <div className="search-container">
                        <input name={`input_${Math.random().toString(36).substr(2, 10)}`} autoComplete="new-password" value={selectedVideoUrl} onChange={(e) => setSelectedVideoUrl(e.target.value)}class="youtube-video-url-input"></input>
                        <button  onClick={()=>getVideoInfo(selectedVideoUrl)} className="button-format get-video-button">transcribe</button>
                    </div>


                    {!selectedVideo?
                       <>
                       <br/>
                       enter a url of a youtube video you'd like to transcribe, search or ask gpt about
                       {loadTranscription?
                        <CircularProgress />:<></>
                        }
                       </>:
                       
              
                       <div className="card-tool-container">

                    
                       <div className="card-tool">
                           <img src={selectedVideo.img_url} alt="Card Photo"></img>
                           <h2 className="card-tool-title">{selectedVideo.title}</h2>
                       </div>

                        </div>

                    }

                    </div>
  
        
                </div>

                <div className="divider">
                <Divider />
                </div>

            </div>

            <>
            {selectedVideo?
                (!selectedVideo.sentences?
                    <>none</>:

                    (selectedVideo.sentences=='loading'?
                        <>loading</>:
                        (selectedVideo.sentences=='unavailable'?
                            <>unavailable</>:
                            <div className="wrapper gpt-interaction">
                    
                                          
                {selectedVideo&&selectedVideo.sentences&&selectedVideo.sentences!='loading'&&selectedVideo.sentences!='unavailable'?


                    <div className="response-wrapper">

                    <div className="search-container-2">
                            <input 
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                                name={`input_${Math.random().toString(36).substr(2, 10)}`} 
                                autocomplete="off"
                                class="youtube-video-url-input-2">
                            </input>
                        <button  onClick={()=>askGptAboutVideo(q)} className="button-format get-video-button">transcribe</button>
                    </div>

                    <div className={`chatgpt-response ${gptResponse ? 'expanded' : 'expanded'}` }>

                    <pre>

                        <div className="section-title" >Response</div>
                            {loadGptResponse?
                            <CircularProgress />:
                            
                                (gptResponse?
                                    <>{gptResponse}</>:

                                        <>Enter a question for GPT</>)

                            }
                        </pre>
                    </div>:


                    </div>:

                    <></>
                    }
          
                            {gptResponse?
                                <ul className="card-grid">
                                {search(data).map((item) => (
                                    <li >
                                        <article className="card" key={item.videoId}>
                                            <div className="card-items">
                                                <div className="card-image">
                                                    <img
                                                        src={item.img_url}
                                                        alt={item.title}
                                                    />
                                                </div>
                                                <div className="card-content">
                                                    <h3 className="card-name">{item.title}</h3>
                                                    <ol className="card-list">
                                                        <li>
                                                            
                                                            <span>{item.publishedAt}</span>
                                                            {gptResponse? 
                                                                
                                                                <div className="card-excerpt">
                                                                <span className="excerpt-time"> <OpenInNewIcon className="excerpt-icon" onClick={()=>openVideoAtTime(item.videoUrl,item.timeStamp)}/>{`${formatSecondsToTimestamp(item.timeStamp)}: `}</span>
                                                                    {item.excerpt}
                                                                </div>:
                                                                <></>
                                                            }
                                                            <span></span>
                                                
                                                        </li>
                                                    
                                                    </ol>
                                                </div>
                                            </div>
                                    
                                        </article>
                                    </li>
                                ))}
                                
                                </ul>:
                                <></>
                            }

        

  

                            <div className={`transcript-response ${selectedVideo.sentences ? '' : 'hide'}` }>
                            
                    
                                
                            
                                <div className="section-title" >Transcript</div>
                            
                                {selectedVideo.sentences.map((item)=> (
                            
                                    <><span className="excerpt-time" onClick={()=>openVideoAtTime(selectedVideo.videoUrl,item.start)}>{formatSecondsToTimestamp(item.start)}</span><p>{item.text}</p></>
                                    

                                ))}
                                

                            </div>

                            </div>
                        )

                    )

                ):<></>
            }

            </>
           
            </>
        );

        
    }
}



export default SearchVideos;
//ReactDOM.render(<App />, document.getElementById("root"));
