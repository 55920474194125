import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
  useHistory,
  useNavigate
} from "react-router-dom";
import SearchChannels from './SearchChannels';
import SearchVideos from './SearchVideos';
import SearchAllVideos from './SearchAllVideos';

import { useState, useEffect, useRef } from "react";


import SearchTrends from './TrendSearch';
import './style.scss'





// import reportWebVitals from './reportWebVitals';


function App() {

  const [pageType, setPageType] = useState("");

 
  useEffect(() => {
    setPageType( window.location.href.split("/").at(-1))
    console.log( window.location.href.split("/").at(-1))
  },[window.location])


  return (
    <div className="app-container">

   <Router>
   <div className="site-header">
    <div><Link className="site-title" to="/"> {pageType ==  "channel"? <>ChannelGPT</>:<>VideoGPT</>}</Link></div>
   <div className="site-nav"><Link className="button-format nav-item " onClick={()=>setPageType("video")} to="/"> VideoGPT</Link>
   <Link  onClick={()=>setPageType("channel")} className="button-format nav-item " to="/channel"> ChannelGPT</Link></div>
   </div>


      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/videos/UCamLstJyCa-t5gfZegxsFMw">Home</Link>
            </li>
          s
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
       <Routes>

          <Route path="/channel/:channelId" element ={<SearchAllVideos />}></Route>
          <Route path="/channel" element ={<SearchChannels />}></Route>
          {/* <Route path="/" element ={<SearchChannels />}></Route> */}
          <Route path="/" element ={<SearchVideos />}></Route>

          </Routes>
    
    
      </div>
    </Router>
    </div>
  );
}

export default App;
